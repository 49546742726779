



















































































































import Vue from 'vue';
import ReactionBarGraph from '@/components/Viewer/ReactionBarGraph.vue';
export default Vue.extend({
  name: 'StatsCard',
  components: { ReactionBarGraph },
  props: {
    activeUserCount: {
      type: Number,
      required: true,
      default: 1,
    },
    reactionsCount: {
      type: Number,
      required: true,
      default: 1,
    },
    reactions: {
      type: Array,
      required: true,
    },
    yourReactionsCount: {
      type: Number,
      required: true,
      default: 1,
    },
    theme: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      swiperOptions: {
        loop: true,
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 2,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        // Some Swiper option/callback...
      },
    };
  },
});
