













































































import Vue from 'vue';
import {
  formatDateTime,
  millisToTimeString,
  millisToMinutes,
} from '@/utils/tools';
import { mapGetters } from 'vuex';
import SessionWrapper from '@/components/Viewer/SessionWrapper.vue';
import LiquidButton from '@/components/Viewer/LiquidButton.vue';
import StatsCard from '@/components/Viewer/StatsCard.vue';
import ThemeModel from '@/dtos/theme';

export default Vue.extend({
  components: { SessionWrapper, LiquidButton, StatsCard },
  data() {
    return {
      now: new Date(),
      reactionLevels: {
        'thumbs-up': 100,
        love: 100,
        funny: 100,
        sad: 100,
        claps: 100,
        rocket: 100,
        'too-fast': 100,
        'too-long': 100,
      } as any,
    };
  },
  async mounted() {
    await this.$store.dispatch('viewer/isSessionValid', {
      sessionToken: this.$route.params.sessionId,
      goToSession: false,
    });
    if (
      this.activeSession.deactivatedMenuItems &&
      this.activeSession.deactivatedMenuItems.includes('reactions')
    ) {
      this.$router.push({
        name: 'Viewer Session Info',
      });
    } else {
      this.$router
        .push({
          name: 'Viewer Session Reactions',
        })
        .catch(() => {
          // Mitigates NavigationDuplicated Error on load
          return;
        });
    }
  },
  created() {
    // Update current time every 10s
    setInterval(() => {
      this.now = new Date();
    }, 10000);
  },
  computed: {
    ...mapGetters({
      isSessionValid: 'viewer/isSessionValid',
      activeSession: 'viewer/getActiveSession',
      sessionViewers: 'viewer/getSessionViewers',
      sessionTheme: 'viewer/getSessionTheme',
      userId: 'auth/getUserId',
    }),
    theme(): ThemeModel {
      return this.sessionTheme as ThemeModel;
    },
    hasSessionStarted() {
      if (this.activeSession) {
        if (this.now > this.activeSession.startAt.toDate()) {
          return true;
        }
      }
      return false;
    },
    elapsedTime(): string {
      if (this.activeSession) {
        const elapsedTime =
          this.now.getTime() - this.activeSession.startAt.toDate().getTime();
        return millisToTimeString(elapsedTime);
      }
      return '-';
    },
    remainingTime(): string {
      if (this.activeSession) {
        const remainingTime =
          this.activeSession.endAt.toDate().getTime() - this.now.getTime();
        return millisToTimeString(remainingTime);
      }
      return '-';
    },
    computedReactionsCount(): number {
      let countReactions = 0;
      if (this.activeSession) {
        if ('reactions' in this.activeSession) {
          for (const count of Object.values(this.activeSession.reactions)) {
            countReactions += count as number;
          }
        }
      }
      return countReactions;
    },
    computedReactionsForGraph(): any {
      if (this.activeSession) {
        if ('reactions' in this.activeSession) {
          const { reactions } = this.activeSession;
          if (this.theme.limitReactions && this.theme.reactions) {
            const sessionReactions = [];
            for (const reactionName of this.theme.reactions) {
              switch (reactionName) {
                case 'thumbs-up':
                  sessionReactions.push({
                    name: 'thumbs-up',
                    imageName: 'reaction-thumbs-up.png',
                    color: this.theme.primaryColor,
                    value: reactions.thumbsUpCount,
                    fillLevel:
                      (reactions.thumbsUpCount / this.computedReactionsCount) *
                      100,
                    label: this.$t('views.thumbsUp'),
                  });
                  break;
                case 'thumbs-down':
                  sessionReactions.push({
                    name: 'thumbs-down',
                    imageName: 'reaction-thumbs-down.png',
                    color: this.theme.primaryColor,
                    value: reactions.thumbsDownCount,
                    fillLevel:
                      (reactions.thumbsDownCount /
                        this.computedReactionsCount) *
                      100,
                    label: this.$t('views.thumbsDown'),
                  });
                  break;
                case 'love':
                  sessionReactions.push({
                    name: 'love',
                    imageName: 'reaction-love.png',
                    color: this.theme.primaryColor,
                    value: reactions.heartCount,
                    fillLevel:
                      (reactions.heartCount / this.computedReactionsCount) *
                      100,
                    label: this.$t('views.loveIt'),
                  });
                  break;
                case 'funny':
                  sessionReactions.push({
                    name: 'funny',
                    imageName: 'reaction-funny.png',
                    color: this.theme.primaryColor,
                    value: reactions.funEmojiCount,
                    fillLevel:
                      (reactions.funEmojiCount / this.computedReactionsCount) *
                      100,
                    label: this.$t('views.laughing'),
                  });
                  break;
                case 'sad':
                  sessionReactions.push({
                    name: 'sad',
                    imageName: 'reaction-sad.png',
                    color: this.theme.primaryColor,
                    value: reactions.sadEmojiCount,
                    fillLevel:
                      (reactions.sadEmojiCount / this.computedReactionsCount) *
                      100,
                    label: this.$t('views.crying'),
                  });
                  break;
                case 'smile':
                  sessionReactions.push({
                    name: 'smile',
                    imageName: 'reaction-smile.png',
                    color: this.theme.primaryColor,
                    value: reactions.sadEmojiCount,
                    fillLevel:
                      (reactions.sadEmojiCount / this.computedReactionsCount) *
                      100,
                    label: this.$t('views.smile'),
                  });
                  break;
                case 'claps':
                  sessionReactions.push({
                    name: 'claps',
                    imageName: 'reaction-claps.png',
                    color: this.theme.primaryColor,
                    value: reactions.clapsCount,
                    fillLevel:
                      (reactions.clapsCount / this.computedReactionsCount) *
                      100,
                    label: this.$t('views.clapping'),
                  });
                  break;
                case 'rocket':
                  sessionReactions.push({
                    name: 'rocket',
                    imageName: 'reaction-rocket.png',
                    color: this.theme.primaryColor,
                    value: reactions.rocketCount,
                    fillLevel:
                      (reactions.rocketCount / this.computedReactionsCount) *
                      100,
                    label: this.$t('views.rocket'),
                  });
                  break;
                default:
                  break;
              }
            }
            return sessionReactions;
          }
          return [
            {
              name: 'thumbs-up',
              imageName: 'reaction-thumbs-up.png',
              color: this.theme.primaryColor,
              value: reactions.thumbsUpCount,
              fillLevel:
                (reactions.thumbsUpCount / this.computedReactionsCount) * 100,
              label: this.$t('views.thumbsUp'),
            },
            {
              name: 'love',
              imageName: 'reaction-love.png',
              color: this.theme.primaryColor,
              value: reactions.heartCount,
              fillLevel:
                (reactions.heartCount / this.computedReactionsCount) * 100,
              label: this.$t('views.loveIt'),
            },
            {
              name: 'funny',
              imageName: 'reaction-funny.png',
              color: this.theme.primaryColor,
              value: reactions.funEmojiCount,
              fillLevel:
                (reactions.funEmojiCount / this.computedReactionsCount) * 100,
              label: this.$t('views.laughing'),
            },
            {
              name: 'sad',
              imageName: 'reaction-sad.png',
              color: this.theme.primaryColor,
              value: reactions.sadEmojiCount,
              fillLevel:
                (reactions.sadEmojiCount / this.computedReactionsCount) * 100,
              label: this.$t('views.crying'),
            },
            {
              name: 'claps',
              imageName: 'reaction-claps.png',
              color: this.theme.primaryColor,
              value: reactions.clapsCount,
              fillLevel:
                (reactions.clapsCount / this.computedReactionsCount) * 100,
              label: this.$t('views.clapping'),
            },
            {
              name: 'rocket',
              imageName: 'reaction-rocket.png',
              color: this.theme.primaryColor,
              value: reactions.rocketCount,
              fillLevel:
                (reactions.rocketCount / this.computedReactionsCount) * 100,
              label: this.$t('views.rocket'),
            },
          ];
        }
      }
      return [];
    },
    computedReactions() {
      if (this.theme.limitReactions && this.theme.reactions) {
        const sessionReactions = [];
        for (const reactionName of this.theme.reactions) {
          switch (reactionName) {
            case 'thumbs-up':
              sessionReactions.push({
                name: 'thumbs-up',
                hasLimit: false,
                loadingInterval: 100,
                fillRate: 3.33,
                interval: null,
              });
              break;
            case 'thumbs-down':
              sessionReactions.push({
                name: 'thumbs-down',
                hasLimit: false,
                loadingInterval: 100,
                fillRate: 3.33,
                interval: null,
              });
              break;
            case 'love':
              sessionReactions.push({
                name: 'love',
                hasLimit: false,
                loadingInterval: 100,
                fillRate: 3.33,
                interval: null,
              });
              break;
            case 'funny':
              sessionReactions.push({
                name: 'funny',
                hasLimit: false,
                loadingInterval: 100,
                fillRate: 3.33,
                interval: null,
              });
              break;
            case 'sad':
              sessionReactions.push({
                name: 'sad',
                hasLimit: false,
                loadingInterval: 100,
                fillRate: 3.33,
                interval: null,
              });
              break;
            case 'smile':
              sessionReactions.push({
                name: 'smile',
                hasLimit: false,
                loadingInterval: 100,
                fillRate: 3.33,
                interval: null,
              });
              break;
            case 'claps':
              sessionReactions.push({
                name: 'claps',
                hasLimit: false,
                loadingInterval: 100,
                fillRate: 3.33,
                interval: null,
              });
              break;
            case 'rocket':
              sessionReactions.push({
                name: 'rocket',
                hasLimit: false,
                loadingInterval: 100,
                fillRate: 3.33,
                interval: null,
              });
              break;
            default:
              break;
          }
        }
        return sessionReactions;
      }
      return [
        {
          name: 'thumbs-up',
          hasLimit: false,
          loadingInterval: 100,
          fillRate: 3.33,
          interval: null,
        },
        {
          name: 'love',
          hasLimit: false,
          loadingInterval: 100,
          fillRate: 3.33,
          interval: null,
        },
        {
          name: 'funny',
          hasLimit: false,
          loadingInterval: 100,
          fillRate: 3.33,
          interval: null,
        },
        {
          name: 'sad',
          hasLimit: false,
          loadingInterval: 100,
          fillRate: 3.33,
          interval: null,
        },
        { name: 'claps', hasLimit: false },
        {
          name: 'rocket',
          hasLimit: false,
          loadingInterval: 100,
          fillRate: 3.33,
          interval: null,
        },
      ];
    },
    computedUserReactionsCount(): number {
      if (this.sessionViewers) {
        const [viewer] = this.sessionViewers.filter(
          (el: any) => el.id === this.userId
        );
        return viewer ? viewer.reactionsCount : 0;
      }
      return 0;
    },
    computedActiveUsers(): number {
      if (this.sessionViewers) {
        return this.sessionViewers.length;
      }
      return 0;
    },
  },
  methods: {
    submitReaction(reaction: any): void {
      if (reaction.name) {
        const elapsedTime =
          this.now.getTime() - this.activeSession.startAt.toDate().getTime();
        const currentMin = millisToMinutes(elapsedTime);
        if (reaction.hasLimit) {
          const reactionName = reaction.name as string;
          if (this.reactionLevels[reactionName] >= 100) {
            this.$store.dispatch('viewer/submitReaction', {
              reactionName,
              currentMin,
            });
            this.reactionLevels[reactionName] = 0;
            // Fire Loading for this animation
            reaction.interval = setInterval(() => {
              if (this.reactionLevels[reactionName] >= 100) {
                clearInterval(reaction.interval);
              }
              this.reactionLevels[reactionName] += reaction.fillRate;
            }, reaction.loadingInterval);
          }
        } else {
          this.$store.dispatch('viewer/submitReaction', {
            reactionName: reaction.name,
            currentMin,
          });
        }
      }
    },

    formatDateTime(input: any): string {
      return formatDateTime(input);
    },
  },
});
