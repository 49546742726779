
































import Vue from 'vue';
import ReactionVector from './ReactionVector.vue';
export default Vue.extend({
  name: 'ReactionBarItem',
  components: { ReactionVector },
  props: ['color', 'fillLevel', 'label', 'imageSrc', 'name', 'value', 'theme'],
  computed: {
    computedImageSrc(): any {
      return require('@/assets/reaction-icons/' + this.imageSrc);
    },
  },
});
