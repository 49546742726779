

















import Vue from 'vue';
import ReactionBarItem from './ReactionBarItem.vue';
export default Vue.extend({
  components: { ReactionBarItem },
  props: {
    reactions: {
      type: Array,
      default: () => [],
      required: true,
    },
    theme: {
      type: Object,
      required: true,
    },
  },
});
