

































import Vue from 'vue';
import ReactionVector from './ReactionVector.vue';
export default Vue.extend({
  components: { ReactionVector },
  props: {
    active: {
      type: Boolean,
      default: true,
      required: true,
    },
    fillLevel: {
      type: Number,
      default: 0,
      required: true,
    },
    showLoading: {
      type: Boolean,
      default: false,
      required: true,
    },
    reaction: {
      type: Object,
      required: true,
    },
    onClick: {
      type: Function,
      required: true,
    },
    theme: {
      type: Object,
      required: true,
    },
  },
  methods: {
    handleClick() {
      this.onClick(this.reaction);
    },
  },
});
